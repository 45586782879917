export const gray = {
  25: 'hsl(0, 0%, 98%)',
  50: 'hsl(0, 0%, 96%)',
  100: 'hsl(0, 0%, 94%)',
  200: 'hsl(0, 0%, 92%)',
  300: 'hsl(0, 0%, 88%)',
  400: 'hsl(0, 0%, 81%)',
  450: 'hsl(0, 0%, 77%)',
  500: 'hsl(0, 0%, 71%)',
  600: 'hsl(0, 0%, 59%)',
  700: 'hsl(0, 0%, 44%)',
  750: 'hsl(0, 0%, 38%)',
  800: 'hsl(0, 0%, 31%)',
  900: 'hsl(0, 0%, 19%)',
  1000: 'hsl(0, 0%, 7%)',
} as const

export const grayTranslucent = {
  25: 'hsla(0, 0%, 7%, 0.002)',
  50: 'hsla(0, 0%, 7%, 0.02)',
  100: 'hsla(0, 0%, 7%, 0.05)',
  200: 'hsla(0, 0%, 7%, 0.07)',
  300: 'hsla(0, 0%, 7%, 0.11)',
  400: 'hsla(0, 0%, 7%, 0.19)',
  450: 'hsla(0, 0%, 7%, 0.23)',
  500: 'hsla(0, 0%, 7%, 0.3)',
  600: 'hsla(0, 0%, 7%, 0.43)',
  700: 'hsla(0, 0%, 7%, 0.6)',
  750: 'hsla(0, 0%, 7%, 0.66)',
  800: 'hsla(0, 0%, 7%, 0.73)',
  900: 'hsla(0, 0%, 7%, 0.87)',
  1000: 'hsla(0, 0%, 0%, 0.93)',
} as const

export const grayTranslucentDark = {
  25: 'hsla(0, 0%, 100%, 0.98)',
  50: 'hsla(0, 0%, 98%, 0.98)',
  100: 'hsla(0, 0%, 98%, 0.96)',
  200: 'hsla(0, 0%, 98%, 0.93)',
  300: 'hsla(0, 0%, 98%, 0.89)',
  400: 'hsla(0, 0%, 98%, 0.81)',
  450: 'hsla(0, 0%, 98%, 0.77)',
  500: 'hsla(0, 0%, 98%, 0.7)',
  600: 'hsla(0, 0%, 98%, 0.57)',
  700: 'hsla(0, 0%, 98%, 0.41)',
  750: 'hsla(0, 0%, 98%, 0.34)',
  800: 'hsla(0, 0%, 98%, 0.27)',
  900: 'hsla(0, 0%, 98%, 0.13)',
  1000: 'hsla(0, 0%, 98%, 0)',
} as const

export const red = {
  50: 'hsl(9, 100%, 96%)',
  100: 'hsl(8, 94%, 93%)',
  200: 'hsl(7, 80%, 88%)',
  300: 'hsl(8, 63%, 83%)',
  400: 'hsl(8, 55%, 80%)',
  500: 'hsl(8, 50%, 77%)',
  600: 'hsl(7, 100%, 61%)',
  650: 'hsl(7, 100%, 56%)',
  700: 'hsl(8, 50%, 54%)',
  800: 'hsl(8, 70%, 40%)',
  900: 'hsl(8, 80%, 24%)',
} as const

export const pink = {
  50: 'hsl(324 100% 98%)',
  100: 'hsl(325 76% 95%)',
  200: 'hsl(323 61% 90%)',
  300: 'hsl(323 45% 85%)',
  400: 'hsl(323 39% 82%)',
  500: 'hsl(323 35% 79%)',
  600: 'hsl(323 77% 66%)',
  650: 'hsl(323 77% 60%)',
  700: 'hsl(323 30% 45%)',
  800: 'hsl(324 40% 33%)',
  900: 'hsl(323 34% 28%)',
} as const

export const amber = {
  50: 'hsl(44, 100%, 90%)',
  100: 'hsl(44, 95%, 85%)',
  200: 'hsl(44, 90%, 80%)',
  300: 'hsl(44, 85%, 77%)',
  400: 'hsl(44, 80%, 74%)',
  500: 'hsl(44, 76%, 71%)',
  600: 'hsl(44, 100%, 50%)',
  650: 'hsl(44, 100%, 48%)',
  700: 'hsl(30, 50%, 45%)',
  800: 'hsl(30, 80%, 30%)',
  900: 'hsl(30, 89%, 22%)',
} as const

export const yellow = {
  50: 'hsl(55, 100%, 90%)',
  100: 'hsl(55, 86%, 86%)',
  200: 'hsl(56, 69%, 81%)',
  300: 'hsl(56, 54%, 76%)',
  400: 'hsl(55, 50%, 73%)',
  500: 'hsl(55, 45%, 70%)',
  600: 'hsl(55, 100%, 70%)',
  700: 'hsl(55, 100%, 60%)',
  800: 'hsl(50, 42%, 36%)',
  900: 'hsl(50, 81%, 20%)',
} as const

export const green = {
  50: 'hsl(95, 100%, 90%)',
  100: 'hsl(95, 74%, 85%)',
  200: 'hsl(95, 61%, 80%)',
  300: 'hsl(95, 45%, 77%)',
  400: 'hsl(95, 40%, 74%)',
  500: 'hsl(95, 35%, 71%)',
  600: 'hsl(140, 70%, 47%)',
  700: 'hsl(95, 20%, 45%)',
  800: 'hsl(95, 22%, 32%)',
  900: 'hsl(95, 22%, 22%)',
} as const

export const teal = {
  50: 'hsl(174 100% 96%)',
  100: 'hsl(175 69% 90%)',
  200: 'hsl(174 55% 85%)',
  300: 'hsl(176 39% 80%)',
  400: 'hsl(174 35% 77%)',
  500: 'hsl(174 30% 74%)',
  600: 'hsl(175 61% 65%)',
  650: 'hsl(175 61% 60%)',
  700: 'hsl(174 20% 51%)',
  800: 'hsl(174 30% 35%)',
  900: 'hsl(175 35% 25%)',
} as const

export const blue = {
  50: 'hsl(201 100% 95%)',
  100: 'hsl(201 84% 90%)',
  200: 'hsl(201 71% 85%)',
  300: 'hsl(201 55% 80%)',
  400: 'hsl(201 50% 77%)',
  500: 'hsl(201 45% 74%)',
  600: 'hsl(201 96% 65%)',
  650: 'hsl(202 95% 55%)',
  700: 'hsl(210 35% 45%)',
  800: 'hsl(210 45% 34%)',
  900: 'hsl(210 50% 24%)',
} as const

export const purple = {
  50: 'hsl(264 100% 98%)',
  100: 'hsl(265 78% 93%)',
  200: 'hsl(265 64% 88%)',
  300: 'hsl(265 47% 83%)',
  400: 'hsl(265 43% 80%)',
  500: 'hsl(265 38% 77%)',
  600: 'hsl(265 100% 62%)',
  650: 'hsl(265 100% 57%)',
  700: 'hsl(265 20% 44%)',
  800: 'hsl(265 22% 34%)',
  900: 'hsl(265 30% 25%)',
} as const

export const highlight = {
  blue: { fill1: blue[100], fill2: blue[200], fill3: blue[300] },
  purple: { fill1: purple[100], fill2: purple[200], fill3: purple[300] },
  yellow: { fill1: yellow[100], fill2: yellow[200], fill3: yellow[300] },
  teal: { fill1: teal[100], fill2: teal[200], fill3: teal[300] },
  pink: { fill1: pink[100], fill2: pink[200], fill3: pink[300] },
} as const

export const signal = {
  green: { fill: green[100], highlight: green[300], element: green[600], text: green[800] },
  amber: { fill: amber[100], highlight: amber[300], element: amber[600], text: amber[800] },
  red: { fill: red[100], highlight: red[300], element: red[600], text: red[800] },
  blue: { fill: blue[100], highlight: blue[300], element: blue[600], text: blue[800] },
} as const

export const colors = {
  gray25: gray[25],
  gray50: gray[50],
  gray100: gray[100],
  gray200: gray[200],
  gray300: gray[300],
  gray400: gray[400],
  gray500: gray[500],
  gray600: gray[600],
  gray700: gray[700],
  gray800: gray[800],
  gray900: gray[900],
  gray1000: gray[1000],
  grayTranslucent25: grayTranslucent[25],
  grayTranslucent50: grayTranslucent[50],
  grayTranslucent100: grayTranslucent[100],
  grayTranslucent200: grayTranslucent[200],
  grayTranslucent300: grayTranslucent[300],
  grayTranslucent400: grayTranslucent[400],
  grayTranslucent500: grayTranslucent[500],
  grayTranslucent600: grayTranslucent[600],
  grayTranslucent700: grayTranslucent[700],
  grayTranslucent800: grayTranslucent[800],
  grayTranslucent900: grayTranslucent[900],
  grayTranslucent1000: grayTranslucent[1000],
  grayTranslucentDark25: grayTranslucentDark[25],
  grayTranslucentDark50: grayTranslucentDark[50],
  grayTranslucentDark100: grayTranslucentDark[100],
  grayTranslucentDark200: grayTranslucentDark[200],
  grayTranslucentDark300: grayTranslucentDark[300],
  grayTranslucentDark400: grayTranslucentDark[400],
  grayTranslucentDark500: grayTranslucentDark[500],
  grayTranslucentDark600: grayTranslucentDark[600],
  grayTranslucentDark700: grayTranslucentDark[700],
  grayTranslucentDark800: grayTranslucentDark[800],
  grayTranslucentDark900: grayTranslucentDark[900],
  grayTranslucentDark1000: grayTranslucentDark[1000],
  green50: green[50],
  green100: green[100],
  green200: green[200],
  green300: green[300],
  green500: green[500],
  green600: green[600],
  green700: green[700],
  green800: green[800],
  green900: green[900],
  blue50: blue[50],
  blue100: blue[100],
  blue200: blue[200],
  blue300: blue[300],
  blue400: blue[400],
  blue500: blue[500],
  blue600: blue[600],
  blue700: blue[700],
  blue800: blue[800],
  blue900: blue[900],
  teal50: teal[50],
  teal100: teal[100],
  teal200: teal[200],
  teal300: teal[300],
  teal400: teal[400],
  teal500: teal[500],
  teal600: teal[600],
  teal700: teal[700],
  teal800: teal[800],
  teal900: teal[900],
  purple50: purple[50],
  purple100: purple[100],
  purple200: purple[200],
  purple300: purple[300],
  purple400: purple[400],
  purple500: purple[500],
  purple600: purple[600],
  purple700: purple[700],
  purple800: purple[800],
  purple900: purple[900],
  red50: red[50],
  red100: red[100],
  red200: red[200],
  red300: red[300],
  red500: red[500],
  red600: red[600],
  red700: red[700],
  red800: red[800],
  red900: red[900],
  pink50: pink[50],
  pink100: pink[100],
  pink200: pink[200],
  pink300: pink[300],
  pink400: pink[400],
  pink500: pink[500],
  pink600: pink[600],
  pink700: pink[700],
  pink800: pink[800],
  pink900: pink[900],
  yellow50: yellow[50],
  yellow100: yellow[100],
  yellow200: yellow[200],
  yellow300: yellow[300],
  yellow500: yellow[500],
  yellow600: yellow[600],
  yellow700: yellow[700],
  yellow800: yellow[800],
  yellow900: yellow[900],
  amber50: amber[50],
  amber100: amber[100],
  amber200: amber[200],
  amber300: amber[300],
  amber500: amber[500],
  amber600: amber[600],
  amber700: amber[700],
  amber800: amber[800],
  amber900: amber[900],
  black: '#000000',
  white: '#ffffff',

  // Alias colors
  dark: gray[1000],
  light: gray[25],

  // Text colors
  textPrimary: gray[1000],
  textSecondaryOnGray: gray[750],
  textSecondary: gray[700],
  textTertiary: gray[500],
  textDisabled: gray[400],
  textNegative: gray[25],
  textGreen: signal.green.text,
  textAmber: signal.amber.text,
  textRed: signal.red.text,
  textTranslucentPrimary: grayTranslucent[1000],
  textTranslucentSecondary: grayTranslucent[700],
  textTranslucentTertiary: gray[500],

  // Semantic colors
  // Background
  backgroundStandard: gray[25],
  backgroundFrostedGlass: 'hsla(0, 0%, 98%, 0.75)',

  // Border
  borderPrimary: grayTranslucent[200],
  borderSecondary: grayTranslucent[300],
  // Deprecated
  borderOpaque1: gray[200],
  borderTranslucent1: grayTranslucent[200],
  borderOpaque2: gray[300],
  borderTranslucent2: grayTranslucent[300],
  borderOpaque3: gray[700],
  borderTranslucent3: grayTranslucent[700],
  borderOpaque4: gray[800],
  borderTranslucent4: grayTranslucent[800],
  borderFocusedInput: grayTranslucent[300],

  // Fills Deprecated
  /** @deprecated  */
  opaque1: gray[100],
  /** @deprecated  */
  translucent1: grayTranslucent[100],
  /** @deprecated  */
  opaque2: gray[300],
  /** @deprecated  */
  translucent2: grayTranslucent[300],
  /** @deprecated  */
  opaque3: gray[400],
  /** @deprecated  */
  translucent3: grayTranslucent[400],
  offWhite: gray[25],
  offWhiteTranslucent: grayTranslucent[25],

  // Fills
  fillOpaquePrimary: gray[1000],
  fillTranslucentPrimary: grayTranslucent[1000],
  fillOpaqueSecondary: gray[700],
  fillTranslucentSecondary: grayTranslucent[700],
  fillOpaqueTertiary: gray[500],
  fillTranslucentTertiary: grayTranslucent[500],
  fillOpaqueDisabled: gray[400],
  fillTranslucentDisabled: grayTranslucent[400],
  fillOpaqueNegative: gray[25],
  fillTranslucentNegative: grayTranslucent[25],

  // Surface
  surfaceOpaquePrimary: gray[100],
  surfaceOpaqueSecondary: gray[200],

  // Highlight colors
  // Used as fill on light backgrounds
  blueFill1: highlight.blue.fill1,
  purpleFill1: highlight.purple.fill1,
  yellowFill1: highlight.yellow.fill1,
  tealFill1: highlight.teal.fill1,
  pinkFill1: highlight.pink.fill1,
  // Used as fill on gray backgrounds
  blueFill2: highlight.blue.fill2,
  purpleFill2: highlight.purple.fill2,
  yellowFill2: highlight.yellow.fill2,
  tealFill2: highlight.teal.fill2,
  pinkFill2: highlight.pink.fill2,
  // Used as fill on highlight background in lighter color
  blueFill3: highlight.blue.fill3,
  purpleFill3: highlight.purple.fill3,
  yellowFill3: highlight.yellow.fill3,
  tealFill3: highlight.teal.fill3,
  pinkFill3: highlight.pink.fill3,

  // Signal colors
  signalGreenFill: signal.green.fill,
  signalGreenHighlight: signal.green.highlight,
  signalGreenElement: signal.green.element,
  signalGreenText: signal.green.text,
  signalAmberFill: signal.amber.fill,
  signalAmberHighlight: signal.amber.highlight,
  signalAmberElement: signal.amber.element,
  signalAmberText: signal.amber.text,
  signalRedFill: signal.red.fill,
  signalRedHighlight: signal.red.highlight,
  signalRedElement: signal.red.element,
  signalRedText: signal.red.text,
  signalBlueFill: signal.blue.fill,
  signalBlueHighlight: signal.blue.highlight,
  signalBlueElement: signal.blue.element,
  signalBlueText: signal.blue.text,

  // Buttons
  buttonPrimary: gray[1000],
  buttonPrimaryHover: gray[900],
  buttonPrimaryDisabled: gray[400],
  buttonPrimaryAlt: green[100],
  buttonPrimaryAltHover: green[200],
  buttonSecondary: grayTranslucent[200],
  buttonSecondaryHover: grayTranslucent[300],
  buttonSecondaryAlt: gray[25],
  buttonSecondaryHoverAlt: grayTranslucentDark[500],
  buttonGhostHover: grayTranslucent[100],
  buttonGhostAltHover: gray[25],
  buttonDisabled: gray[100],
  buttonDisabledText: gray[500],
} as const

export type UIColors = typeof colors
export type UIColorKeys = keyof typeof colors
